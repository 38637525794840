import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import MobileBannerImage from "assets/img/static/bg/tourist-banner-mobile.jpg";
import BannerImage from "assets/img/static/bg/tourist-banner.jpg";
import TouristPlansLayout from "component/products/tourist-plans";
import BuildOwnBundle from "component/_common/plans/buildOwnBundle";
import InnerPageTopBanner3 from "parts/banners/innerPageTopBanner/innerPageTopBanner3";
import FrequentlyQuestions from "parts/frequentlyQuestions";
import MainContainer from "parts/mainContainer";
import SectionTitle from "parts/sectionTitle";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearPhoneBookingData,
  DispatchBookingStep,
} from "state/slice/phoneBooking";
import { RootState } from "state/store";

const ProductTouristPlans = () => {
  const Dispatch = useDispatch();
  const { breakpoints } = useTheme();
  const isMobileDevice = useMediaQuery(breakpoints.down("sm"));

  const { bookingStatus } = useSelector(
    (state: RootState) => state.phoneBooking
  );

  useEffect(() => {
    if (bookingStatus?.bookingDetails || bookingStatus?.billDetails) {
      Dispatch(clearPhoneBookingData());
      return;
    }
    if (bookingStatus?.phoneDetails) {
      Dispatch(DispatchBookingStep(1));
      return;
    }

    Dispatch(clearPhoneBookingData());
  }, [Dispatch, bookingStatus]);

  return (
    <>
      <InnerPageTopBanner3
        title="Traveling to Uganda Soon?"
        subTitle="Stay Connected with our competitive Tourist Plans"
        bannerImage={isMobileDevice ? MobileBannerImage : BannerImage}
        contentBoxSx={{
          justifyContent: {
            xs: "flex-start",
            sm: "flex-end",
          },
        }}
      />

      <section>
        <TouristPlansLayout />
      </section>

      <section>
        <MainContainer>
          <Box py={5}>
            <Box textAlign="center" className="fc-black">
              <SectionTitle
                title="Not seeing a plan that suits you?"
                subTitle="Don’t worry, build one that fits your needs."
              />
            </Box>

            <Box pt={3}>
              <BuildOwnBundle />
            </Box>
          </Box>
        </MainContainer>
      </section>

      <section>
        <MainContainer>
          <Box pb={5}>
            <FrequentlyQuestions />
          </Box>
        </MainContainer>
      </section>
    </>
  );
};

export default ProductTouristPlans;
