import { generateEndpoint } from "functions/helper";
import { GetRandomNumberForBookingProps } from "_interfaces/http-requests/MSISDN";
import { PackageBundleGetBuildBundleRatesProps } from "_interfaces/http-requests/packageBundle";

export const BASE_URL =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://siteapi.talkiomobile.com/api/v1"
    : "https://stagingsiteapi.talkiomobile.com/api/v1";

export const SERVICE_URL =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://serviceapi.talkiomobile.com/api/v1"
    : "https://serviceapi.talkiomobile.com/api/v1";

export const BUSINESS_PORTAL_URL =
  process.env.REACT_APP_ENVIRONMENT === "prod"
    ? "https://business.talkiomobile.com"
    : "https://stagingdp.talkiomobile.com";

/* ================== AUTH ================== */
export const USER_LOGIN_REQUEST = `/user/login-request`;
export const USER_LOGIN_OTP_SUBMIT = `/user/login-otp-submit`;
export const USER_LOGIN_PASSWORD_SUBMIT = `/user/login-password-submit`;
export const AUTH_CHECK = `/auth/auth-check`;
export const AUTH_LOGOUT = `/auth/logout`;
export const CHANGE_PASSWORD_REQUEST = `/user/change-password-request`;
export const VERIFY_OTP_PASSWORD_REQUEST = `/user/verify-otp-password-request`;
export const RESEND_PASSWORD_REQUEST_OTP = `/user/resend-password-request-otp`;
export const CHANGE_PASSWORD = `/user/change-password`;

/* ================== VERIFICATION ================== */
export const VERIFICATION_VERIFY = `/verification/verify`;
export const VERIFICATION_SEND_SMS_OTP = `/verification/send-sms-otp`;
export const VERIFICATION_VERIFY_SMS_OTP = `/verification/verify-sms-otp`;

/* ================== CUSTOMER INFO ================== */
export const GET_CUSTOMER_INFO = `/customer/get-customer-info`;
export const GET_CUSTOMER_BALANCE_SUMMARY = `/customer/get-customer-balance-summary`;
export const GET_CUSTOMER_RECHARGE_HISTORY = `/customer/get-customer-recharge-history`;
export const GET_CUSTOMER_AIRTIME_BALANCE = `/customer/get-airtime-balance`;
export const GET_CUSTOMER_BUNDLE_BALANCE = `/customer/get-bundle-balance`;

/* ================== MSISDN ================== */
export const SEARCH_PHONE_NUMBER = (phoneNumber: string) =>
  `msisdn/search-phone-number/${phoneNumber}`;
export const GET_RANDOM_NUMBER_FOR_BOOKING = (
  PARAMS: GetRandomNumberForBookingProps["PARAMS"]
) => generateEndpoint(`msisdn/get-random-number-for-booking`, PARAMS);

/* ================== PACKAGE BUNDLE ================== */
export const PACKAGE_BUNDLE_GET_ALL = `package-bundle/get-all`;
export const PACKAGE_BUNDLE_GET_BY_ID = (ID: string) =>
  `package-bundle/get-by-id/${ID}`;
export const PACKAGE_BUNDLE_GET_BUILD_BUNDLE_RATES = (
  PARAMS: PackageBundleGetBuildBundleRatesProps["PARAMS"]
) => generateEndpoint(`package-bundle/get-build-bundle-rates`, PARAMS);
export const PACKAGE_BUNDLE_BUILD_BUNDLE_CALCULATION = `package-bundle/build-bundle-calculation`;

/* ================== PHONE BOOKING ================== */
export const PHONE_BOOKING_APPLY = `phone-booking/apply`;
export const PHONE_BOOKING_GET_BY_ID = (ID: string) =>
  `/phone-booking/get-by-id/${ID}`;
export const PHONE_BOOKING_MAKE_PAYMENT_REQUEST = `phone-booking/make-payment-request`;
export const PHONE_BOOKING_CHECK_PAYMENT_REQUEST = (ID: string) =>
  `/phone-booking/check-payment-request/${ID}`;
export const PHONE_BOOKING_APPLY_COUPON = `phone-booking/apply-coupon`;
export const PHONE_BOOKING_REMOVE_COUPON = (ID: string) =>
  `phone-booking/remove-coupon/${ID}`;

/* ================== WALLET ================== */
export const GET_MY_WALLET = `wallet/get-my-wallet`;
export const CREATE_WALLET = `/wallet/create-wallet`;
export const WALLET_CHANGE_PIN_REQUEST = `/wallet/change-PIN-request`;
export const WALLET_RESEND_OTP_FOR_PIN_CHANGE = `/wallet/resend-otp-for-pin-change`;
export const WALLET_VERIFY_OTP_FOR_PIN_CHANGE = `/wallet/verify-otp-for-pin-change`;
export const CHANGE_WALLET_PIN = `/wallet/change-wallet-pin`;
export const WALLET_PAYMENT_SUBMIT = `/wallet-payment/submit-payment`;

/* ================== RECHARGE ================== */
export const GET_CUSTOMER_BY_PHONE = (phoneNumber: string) =>
  `/recharge/get-customer-by-phone/${phoneNumber}`;
export const WALLET_RECHARGE_REQUEST = `/recharge/wallet-recharge-request`;
export const AIRTIME_RECHARGE_REQUEST = `/recharge/airtime-recharge-request`;
export const BUNDLE_RECHARGE_REQUEST = `/recharge/bundle-recharge-request`;
export const BUNDLE_RECHARGE_REQUEST_SECURE = `/recharge/secure-bundle-recharge-request`;
export const RECHARGE_REQUEST_UPDATE = (ID: string) =>
  `/recharge/recharge-request-update/${ID}`;
export const BUILD_BUNDLE_RECHARGE_REQUEST = `/recharge/build-bundle-recharge-request`;

/* ================== PROMOTION ================== */
export const PROMOTION_GET_ALL = `/promotion/get-all`;
export const PROMOTION_GET_BY_ID = (ID: string) => `/promotion/get-by-id/${ID}`;
export const GET_COUNT_FOR_IPHONE_LOTTERY = `/promotion/get-count-for-iphone-lottery`;

/* ================== TALKIO APP ================== */
export const GET_APP_LATEST_VERSION = `/app-version/get-latest-version/KYC`;
