import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTheme } from "@mui/material/styles";
import BusinessIcon from "assets/icons/business";
import ExploreIcon from "assets/icons/explore";
import { BUSINESS_PORTAL_URL } from "config/endpoint";
import RouteConstant from "navigation/constant";
import MainContainer from "parts/mainContainer";
import { useCallback } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const TopHeader = () => {
  const { palette } = useTheme();
  const { hash, pathname } = useLocation();

  const isActiveLink = useCallback(
    (link: string) => {
      if (link.includes("#")) {
        const [path, linkHash] = link.split("#");
        return pathname === path && `#${linkHash}` === hash;
      }
      return !hash && pathname === link;
    },
    [pathname, hash]
  );

  const topBarList = [
    {
      label: "Build your bundle",
      link: RouteConstant.PRODUCT_BUILD_BUNDLE,
    },
    {
      label: "Premium Plans",
      link: RouteConstant.PRODUCT_PREMIUM_PLANS,
    },
    {
      label: "Tourist Plans",
      link: RouteConstant.PRODUCT_TOURIST_PLANS,
    },
    {
      label: "Promotion",
      link: RouteConstant.PROMOTION,
    },
  ];
  return (
    <>
      <MainContainer>
        <Box py={1}>
          <Grid
            container
            spacing={{ xs: 0, sm: 2 }}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={12} sm={8}>
              <List
                disablePadding
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: {
                    xs: "center",
                    sm: "flex-start",
                  },
                  columnGap: { xs: 1, sm: 2 },
                  rowGap: { xs: 0.5, sm: 2 },
                }}
              >
                {topBarList?.map((item, i) => (
                  <ListItem
                    key={i}
                    sx={{
                      p: 0,
                      width: "auto",
                      lineHeight: 1,
                    }}
                  >
                    <NavLink to={item.link}>
                      <Box
                        component="span"
                        textTransform="uppercase"
                        fontSize={{ xs: 12, sm: 14 }}
                        fontWeight={{ sm: 600 }}
                        className="fm-poppins"
                        sx={{
                          color: isActiveLink(item.link)
                            ? palette.primary.main
                            : palette.secondary3.main,
                          display: "inline-block",
                          lineHeight: 1,
                          "&:hover": {
                            color: palette.primary.main,
                          },
                        }}
                      >
                        {item?.label}
                      </Box>
                    </NavLink>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                justifyContent: {
                  xs: "center",
                  sm: "flex-end",
                },
                alignItems: "center",
                gap: 2,
              }}
            >
              <Link
                to={`${BUSINESS_PORTAL_URL}/products/company/`}
                target="_blank"
              >
                <Button
                  variant="text"
                  startIcon={<BusinessIcon width={18} />}
                  color="inherit"
                  size="small"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                    color: palette.common.black,
                    fontSize: { xs: 14, sm: 18 },
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Business
                </Button>
              </Link>
              <Link to={RouteConstant.PRODUCTS}>
                <Button
                  variant="text"
                  startIcon={<ExploreIcon width={14} />}
                  color="inherit"
                  size="small"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                    color: palette.common.black,
                    fontSize: { xs: 14, sm: 18 },
                    p: 0,
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Explore Talkio
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </MainContainer>
    </>
  );
};

export default TopHeader;
