import React, { SVGProps } from "react";

const BusinessIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 -960 960 960"
      fill={props?.fill || props?.color || "#FD9D0E"}
      width={props?.width || "40"}
      height={props?.height || "40"}
    >
      <path d="M479.33-343.33q32.34 0 54.84-22.17t22.5-54.5q0-32.33-22.5-54.5t-54.5-22.17q-32 0-54.17 22.53t-22.17 54.17q0 31.64 22.33 54.14 22.32 22.5 53.67 22.5ZM146.67-120q-27 0-46.84-19.83Q80-159.67 80-186.67v-466.66q0-27 19.83-46.84Q119.67-720 146.67-720H320v-93.33q0-27 19.83-46.84Q359.67-880 386.67-880h186.66q27 0 46.84 19.83Q640-840.33 640-813.33V-720h173.33q27 0 46.84 19.83Q880-680.33 880-653.33v466.66q0 27-19.83 46.84Q840.33-120 813.33-120H146.67Zm0-66.67h666.66v-466.66H146.67v466.66Zm240-533.33h186.66v-93.33H386.67V-720Zm-240 533.33v-466.66 466.66Z" />
    </svg>
  );
};

export default BusinessIcon;
