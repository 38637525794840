import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { BUSINESS_PORTAL_URL } from "config/endpoint";
import RouteConstant from "navigation/constant";
import SectionTitle from "parts/sectionTitle";
import { Link } from "react-router-dom";

const ProductsLayout = () => {
  const listItems = [
    {
      title: "Mobile Plan",
      list: [
        "Bundle Plans",
        "Build Your Bundle",
        "Freedom Plans",
        "Premium Plan",
      ],
      buttonText: "Get Started",
      buttonLink: RouteConstant.SELECT_SERVICE,
      isButtonEnabled: true,
    },
    {
      title: "Talkio Money",
      list: [
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      isButtonEnabled: false,
    },
    {
      title: "Home Internet",
      list: [
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      isButtonEnabled: false,
    },
    {
      title: "Talkio E-Commerce",
      list: [
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      isButtonEnabled: false,
    },
    {
      title: "Promotions",
      list: [
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
        "Choose your plan",
      ],
      buttonText: "Coming Soon",
      buttonLink: "#",
      isButtonEnabled: false,
    },
    {
      title: "Enterprise Solutions",
      list: ["SIP", "IPBX", "VoIP", "SMS (Bulk , A2P)"],
      buttonText: "Get Started",
      buttonLink: `${BUSINESS_PORTAL_URL}/products/company/`,
      buttonTarget: "_blank",
      isButtonEnabled: true,
    },
  ];
  return (
    <>
      <SectionTitle title="Pick your product" />

      <Box pt={3}>
        <Grid container spacing={4}>
          {listItems?.map((item, i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              <Box
                sx={{
                  height: "100%",
                  backgroundColor: "#FFFFFF",
                  border: "1px solid #E5E4E4",
                  borderRadius: "22px",
                  p: 3,
                }}
              >
                <Typography
                  className="fc-black"
                  fontSize={{ xs: 18, sm: 24 }}
                  fontWeight={600}
                >
                  {item?.title}
                </Typography>
                <Box pt={3}>
                  {item?.list?.map((ls, listIndex) => (
                    <Box
                      key={listIndex}
                      display="flex"
                      alignItems="center"
                      fontSize={14}
                      fontWeight={400}
                      pb={2}
                      sx={{
                        color: "#545454",
                      }}
                    >
                      <CheckCircleOutline />
                      <Typography pl={2}>{ls}</Typography>
                    </Box>
                  ))}
                </Box>
                <Box pt={2}>
                  <Link
                    to={item.buttonLink}
                    target={item?.buttonTarget || "_self"}
                    onClick={(e) => {
                      if (!item?.isButtonEnabled) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Button
                      disabled={!item?.isButtonEnabled}
                      variant="contained"
                      className={
                        item?.isButtonEnabled ? "c-bg-primary-var" : "inherit"
                      }
                      sx={{
                        borderRadius: "6px",
                        borderColor: "inherit",
                        minWidth: 220,
                      }}
                    >
                      {item?.buttonText}
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default ProductsLayout;
